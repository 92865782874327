import { Delivery } from '@/models';

export const deliveryNotStartedMock: Delivery = {
  id: undefined,
  cover: '',
  title: '',
  answers: [
    {
      id: 0,
      description: '',
      question: '',
      answer: undefined,
      required: true,
    },
  ],
  publish: false,
  status: undefined,
  profile: {
    id: 0,
    profile_id: 0,
    nickname: 'Duda De Latorre',
    company: '',
    linkedIn: '',
    biography: '',
    profilePhoto: '',
  },
  video: '',
  grade: undefined,
  conceptGrade: 'D',
  recoveryGrade: undefined,
  conceptRecoveryGrade: 'D',
  minimumGrade: 7,
  dueDate: new Date('07/09/2021 00:00:00'),
  recoveryDueDate: new Date('07/10/2021 00:00:00'),
  comments: [],
  observation: '',
  publicationDate: undefined,
  comment: '',
};
