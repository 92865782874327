import {
  datatype,
  name,
  company,
  internet,
  lorem,
} from 'faker';

import { Perfil, Permissoes, TipoDeAcesso } from '@/models/api';

import { CursoMock } from './curso';
import { UsuarioAdminMock } from './usuario';

const { ADMIN } = TipoDeAcesso;
const { EDIT_SCORE_NETWORKING, EDIT_NETWORKING, VIEW_NETWORKING } = Permissoes;
const permissoes = [EDIT_NETWORKING, EDIT_SCORE_NETWORKING, VIEW_NETWORKING];
const produtos = Array(datatype.number({ min: 1, max: 10 })).fill(null).map(() => CursoMock);

export const PerfilAdminMock: Perfil = {
  id: datatype.number(),
  usuario_id: datatype.number(),
  apelido: name.findName(),
  empresa: company.companyName(),
  linkedin: internet.url(),
  biografia: lorem.paragraph(),
  foto_perfil: internet.url(),
  usuario: UsuarioAdminMock,
  primeiro_acesso: datatype.boolean(),
  tipoAcesso: ADMIN,
  permissoes,
  produtos,
  termo_aceito: datatype.boolean(),
};
