
import { Component, Mixins } from 'vue-property-decorator';

import Chip from '@/components/shared/Chip.vue';
import FloatingMenu from '@/components/FloatingMenu/FloatingMenu.vue';
import TextEditor from '@/components/shared/TextEditor.vue';
import Tooltip from '@/components/shared/Tooltip.vue';
import Cover from '@/components/shared/Cover.vue';
import CoverCarousel from '@/components/shared/CoverCarousel.vue';
import NoteBox from '@/components/shared/NoteBox.vue';
import Paginator from '@/components/shared/Paginator.vue';
import SpecialDropdown from '@/components/shared/SpecialDropdown.vue';
import ReportFilter from '@/components/shared/ReportFilter.vue';

import CloseCircle from '@/assets/icons/CloseCircle.svg';
import ChevronDown from '@/assets/icons/ChevronDown.svg';
import QuestionCircle from '@/assets/icons/QuestionCircle.svg';

import {
  ChipType,
  FloatingMenuContent,
  FloatingMenuEvents,
  FloatingMenuModel,
  DataConfirmation,
  DeliveryStatus,
  ModalTipos,
  SaveGradeConfirmData,
  SidebarTypes,
  ReportFilterEvents,
} from '@/models';
import FloatingMenuFlow from '@/mixins/FloatingMenuFlow';
import PublicationModalFlow from '@/mixins/PublicationModalFlow';
import {
  coverMock,
  noteBoxComentario,
  noteBoxNota,
  paginatorFirstPageMock,
  paginatorLastPageMock,
  paginatorMiddlePageMock,
  paginatorOnePageMock,
} from '@/mock';
import { EventBus } from '@/utils';
import { ModalService, SidebarService } from '@/services';
import SaveConfirm from '@/components/GradeBox/SaveConfirm.vue';

const { ALL_SELECTED } = ReportFilterEvents;

@Component({
  name: 'Implementacoes',
  components: {
    Chip,
    CloseCircle,
    ChevronDown,
    FloatingMenu,
    TextEditor,
    Tooltip,
    QuestionCircle,
    Cover,
    CoverCarousel,
    NoteBox,
    Paginator,
    SpecialDropdown,
  },
})

export default class Implementacoes extends Mixins(
  FloatingMenuFlow,
  PublicationModalFlow,
) {
  public chip: ChipType = {
    id: 0,
    name: 'teste',
  }
  public content = '<b>abc</b> quando a gente <i>irrita</i>';
  public coverMock = coverMock;
  public coverMockArray = new Array(20).fill(coverMock);
  public notaMock = noteBoxNota;
  public comentarioMock = noteBoxComentario;
  public paginatorFirstPageMock = paginatorFirstPageMock;
  public paginatorLastPageMock = paginatorLastPageMock;
  public paginatorMiddlePageMock = paginatorMiddlePageMock;
  public paginatorOnePageMock = paginatorOnePageMock;
  private timeout = 0
  private filterData: DataConfirmation = {
    component: ReportFilter,
    allowText: 'BAIXAR',
    denyText: 'CANCELAR',
    allowDisabled: true,
  };

  public floatingMenuData: FloatingMenuModel = {
    data: [{
      type: FloatingMenuContent.CHECKBOX_TYPE,
      id: 1,
      text: 'Sed dolorem dolores',
      action: FloatingMenuEvents.CHECK,
      checked: true,
      keyString: 'autor',
    }, {
      type: FloatingMenuContent.CHECKBOX_TYPE,
      id: 2,
      text: 'Phasellus sed diam',
      action: FloatingMenuEvents.CHECK,
      checked: true,
      keyString: 'autor',
    }, {
      type: FloatingMenuContent.BUTTON_TYPE,
      id: 0,
      text: 'APLICAR',
      action: FloatingMenuEvents.BUTTON_CLICKED,
      keyString: 'autor',
    }],
    tip: true,
    keyString: 'autor',
    openOnlyOne: true,
  }

  mounted() {
    EventBus.$on(SidebarTypes.UNSPLASH, this.respostaEscolhidaSidebar);
    EventBus.$on(ALL_SELECTED, this.enableDownload);
  }

  beforeDestroy() {
    EventBus.$off(SidebarTypes.UNSPLASH, this.respostaEscolhidaSidebar);
    EventBus.$off(ALL_SELECTED, this.enableDownload);
  }

  respostaEscolhidaSidebar(resposta: string) {
    console.log(resposta);
  }

  respostaEscolhida(resposta: boolean) {
    console.log(resposta);
  }

  logarInput() {
    console.log('input no editor');
  }

  openSidebar() {
    SidebarService.abrirSidebar({
      component: SidebarTypes.UNSPLASH,
      fechavel: true,
    });
  }

  abrirModal(): void {
    const data: DataConfirmation<SaveGradeConfirmData> = {
      component: SaveConfirm,
      allowText: 'CONFIRMAR',
      denyText: 'CANCELAR',
      phrase: {
        id: 1,
        from: DeliveryStatus.PUBLISHED,
        to: DeliveryStatus.IN_RECOVERY,
        phrase: 'Você tem certeza que deseja confirmar\nas alterações? ',
      },
    };

    this.openModal(ModalTipos.CONFIRMACAO, data);
  }

  public abrirModalFiltro() {
    this.filterData.allowDisabled = true;
    this.openModal(ModalTipos.CONFIRMACAO, this.filterData, true);
  }

  whenModalResponseIsDeny() {
    console.log('>>> Cancelado <<<');
  }

  publishRequest(): void {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      ModalService.fecharModal();
      console.log('>>> Confirmado <<<');
    }, 2000);
  }

  logPage(page: number) {
    console.log(page);
  }

  logSelect(data: {item: string; value: string}) {
    console.log(data);
  }

  enableDownload(data: {[key: string]: string | number | null}) {
    console.log(data);
    this.filterData.allowDisabled = false;
  }
}
