import { Pagination } from '@/models/api';

export const paginatorFirstPageMock: Pagination<number> = {
  current_page: 1,
  first_page_url: 'first_page_url',
  data: [1, 2, 3, 4, 5],
  from: 1,
  last_page: 12,
  last_page_url: 'last_page_url',
  next_page_url: 'next_page_url',
  path: 'path',
  per_page: 5,
  prev_page_url: 'prev_page_url',
  to: 5,
  total: 60,
};

export const paginatorLastPageMock: Pagination<number> = {
  current_page: 12,
  first_page_url: 'first_page_url',
  data: [1, 2, 3, 4, 5],
  from: 55,
  last_page: 12,
  last_page_url: 'last_page_url',
  next_page_url: 'next_page_url',
  path: 'path',
  per_page: 5,
  prev_page_url: 'prev_page_url',
  to: 60,
  total: 60,
};

export const paginatorMiddlePageMock: Pagination<number> = {
  current_page: 6,
  first_page_url: 'first_page_url',
  data: [1, 2, 3, 4, 5],
  from: 30,
  last_page: 12,
  last_page_url: 'last_page_url',
  next_page_url: 'next_page_url',
  path: 'path',
  per_page: 5,
  prev_page_url: 'prev_page_url',
  to: 35,
  total: 60,
};

export const paginatorOnePageMock: Pagination<number> = {
  current_page: 1,
  first_page_url: 'first_page_url',
  data: [1, 2, 3, 4, 5],
  from: 1,
  last_page: 1,
  last_page_url: 'last_page_url',
  next_page_url: 'next_page_url',
  path: 'path',
  per_page: 5,
  prev_page_url: 'prev_page_url',
  to: 5,
  total: 5,
};
