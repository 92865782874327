import {
  datatype,
  internet,
  date,
  name,
  phone,
  lorem,
} from 'faker';

import { Usuario } from '@/models/api';

export const UsuarioAdminMock: Usuario = {
  id: datatype.number(),
  nome: name.findName(),
  telefone: Number(phone.phoneNumber('##########')),
  celular: Number(phone.phoneNumber('###########')),
  email: internet.email(),
  grupo: datatype.number(),
  cpf: datatype.number(99999999999),
  ativo: datatype.boolean(),
  bloqueado: datatype.boolean(),
  como_conheceu: lorem.word(),
  last_login: date.recent().toISOString(),
  registro: lorem.word(),
  data_criacao: date.past().toISOString(),
  data_atualizacao: date.recent().toISOString(),
  data_nascimento: date.past().toISOString(),
  remember_token: datatype.boolean(),
  avatar: internet.url(),
  regiao_acesso_id: datatype.number(),
  role_id: datatype.number(),
  contact_code_dinamize: datatype.number(),
  senha_expirada: datatype.boolean(),
  auditado: datatype.boolean(),
  tipo_documento: datatype.number(1),
  usuario_responsavel_id: datatype.number(),
  external: datatype.boolean(),
  reference_code: datatype.number(),
  partner_id: datatype.number(),
  onesignal: lorem.word(),
  data_ultima_interacao: date.recent().toISOString(),
  concentimento_dados_fornecidos: String(datatype.number()),
  primeiro_nome: name.firstName(),
};
