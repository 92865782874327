import {
  datatype,
  random,
  lorem,
  date,
} from 'faker';

import { EntregaStatus, MinhaEntrega } from '@/models/api';

export const MinhaEntregaMock: MinhaEntrega = {
  id: datatype.number(),
  status: EntregaStatus[
    random.arrayElement(Object.keys(EntregaStatus)) as keyof typeof EntregaStatus
  ],
  titulo: lorem.sentence(),
  recuperacao: datatype.boolean(),
  nota: String(datatype.number()),
  nota_recuperacao: String(datatype.number()),
  nota_adicional: String(datatype.number()),
  data_publicacao: String(date.recent()),
  data_entrega: String(date.past()),
  data_entrega_recuperacao: String(date.past()),
  data_entrega_adicional: String(date.recent()),
  comentario_do_professor: [],
  data_adicional: String(date.recent()),
};
