import { datatype, lorem, internet } from 'faker';

import { Curso } from '@/models/api';

const assignments = Array(datatype.number({ min: 1, max: 10 }))
  .fill(null).map(() => datatype.number());

export const CursoMock: Curso = {
  id: datatype.number(),
  produto: lorem.sentence(),
  nome: lorem.sentence(),
  logo: internet.url(),
  termo: lorem.word(),
  slug: lorem.slug(),
  title: lorem.sentence(),
  titulo: lorem.sentence(),
  description: lorem.paragraph(),
  data_remocao: datatype.number(),
  assignmentPublicado: datatype.boolean(),
  assignments,
  tipo: lorem.word(),
  cursoId: datatype.number(),
};
