import { datatype, date, lorem } from 'faker';

import { MeuTrabalho } from '@/models/api';

export const MeuTrabalhoMock: MeuTrabalho = {
  id: datatype.number(),
  nome_disciplina: lorem.sentence(),
  data_limite_entrega: new Date(date.soon()).toISOString(),
  data_limite_recuperacao: new Date(date.future()).toISOString(),
  enunciado: lorem.sentence(),
  questoes: Array(datatype.number({ min: 1, max: 10 })).fill(null).map(() => lorem.sentence()),
  entrega: null,
  composicao_nota: false,
};
