import fakeTimers from '@sinonjs/fake-timers';

const dateNowMock = new Date('12-15-2021');
const timestampNowMock = dateNowMock.getTime();

const mockDate = fakeTimers.install({
  now: timestampNowMock,
});

const today = new Date(Date.now());
const currentYear = today.getFullYear();
const lastMonth = today.getMonth();
const currentMonth = today.getMonth() + 1;
const currentDay = today.getDate();

export const DateMock = {
  expired: '01/01/1999',
  expiredUnformatted: '01011999',
  februaryInvalid: {
    day: 31,
    month: 2,
    year: 5555,
    daysOffset: 1,
  },
  februaryValid: {
    day: 28,
    month: 2,
    year: 5555,
    daysOffset: 1,
  },
  formatted: '01/01/3000',
  invalid: '66/66/6666',
  invalidUnformatted: '66666666',
  dayZero: {
    day: 0,
    month: 6,
    year: 7777,
    daysOffset: 1,
  },
  lastDayMonthInvalid: {
    day: 31,
    month: 6,
    year: 7777,
    daysOffset: 1,
  },
  lastDayMonthValid: {
    day: 30,
    month: 6,
    year: 7777,
    daysOffset: 1,
  },
  lastMonthInvalid: {
    day: 30,
    month: lastMonth || 12,
    year: lastMonth ? currentYear : currentYear - 1,
    daysOffset: 0,
  },
  lastMonthValid: {
    day: lastMonth ? currentDay : 30,
    month: lastMonth ? currentMonth : 12,
    year: currentYear,
    daysOffset: 0,
  },
  leapYearInvalid: {
    day: 31,
    month: 2,
    year: 4444,
    daysOffset: 1,
  },
  leapYearValid: {
    day: 29,
    month: 2,
    year: 4444,
    daysOffset: 1,
  },
  monthInvalid: {
    day: 15,
    month: 44,
    year: 3333,
    daysOffset: 1,
  },
  monthValid: {
    day: 15,
    month: 12,
    year: 3333,
    daysOffset: 1,
  },
  monthZero: {
    day: 15,
    month: 0,
    year: 3333,
    daysOffset: 1,
  },
  offset: 1,
  unformatted: '01013000',
  valid: '31/12/6666',
  validUnformatted: '31126666',
  yearValid: {
    day: 1,
    month: 1,
    year: currentYear,
    daysOffset: 0,
  },
  yearInvalid: {
    day: 0,
    month: 0,
    year: 1111,
    daysOffset: 0,
  },
  zeroDay: {
    day: 1,
    month: 6,
    year: 7777,
    daysOffset: 1,
  },
  zeroMonth: {
    day: 15,
    month: 1,
    year: 3333,
    daysOffset: 1,
  },
  apiDateString: {
    date: new Date('2010-10-08 00:00:00'),
    formatted: '2010-10-08',
  },
  apiDateStringFebruary: {
    date: new Date('2010-02-28 00:00:00'),
    formatted: '2010-02-28',
  },
  apiDateStringFebruaryLeapYear: {
    date: new Date('2012-02-29 00:00:00'),
    formatted: '2012-02-29',
  },
  apiDateStringMonth30Days: {
    date: new Date('2012-04-30 00:00:00'),
    formatted: '2012-04-30',
  },
  apiDateStringMonth31Days: {
    date: new Date('2012-03-31 00:00:00'),
    formatted: '2012-03-31',
  },
};

export const DateNowMock = dateNowMock;
export const TimestampNowMock = timestampNowMock;

mockDate.uninstall();
