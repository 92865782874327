import { datatype } from 'faker';

import { Course } from '@/models';

import { CursoMock } from './curso';

export const MultipleCoursesMock: Course[] = Array(datatype.number({ min: 2, max: 10 }))
  .fill(null).map(() => new Course(CursoMock));

export const SingleCourseMock: Course[] = Array(1).fill(null).map(() => new Course(CursoMock));
