
import { Component, Prop, Vue } from 'vue-property-decorator';

import { NoteBoxData, NoteBoxSource } from '@/models';

@Component({
  name: 'NoteBox',
})
export default class NoteBox extends Vue {
  @Prop({ required: true }) data: NoteBoxData;

  public NOTA = NoteBoxSource.NOTA;
  public COMENTARIO = NoteBoxSource.COMENTARIO;
}
