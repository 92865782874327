import { KeywordModel, KeywordPaginated } from '@/models';

const KeywordsMock: KeywordModel[] = [
  {
    id: 1,
    assignmentObjectId: 1,
    deliveryId: 1,
    keyword: 'keyword 1',
  },
  {
    id: 2,
    assignmentObjectId: 2,
    deliveryId: 2,
    keyword: 'keyword 2',
  },
];

export const KeywordPaginatedMock: KeywordPaginated = {
  current_page: 1,
  data: KeywordsMock,
  first_page_url: 'first page url',
  from: 1,
  last_page: 1,
  last_page_url: 'last page url',
  next_page_url: 'next page url',
  path: 'path',
  per_page: 2,
  prev_page_url: '',
  to: 1,
  total: 2,
};
