import { date } from 'faker';

import { Entrega, QuestaoResposta } from '@/models/api';

import { DateUtils } from '@/utils';

const data_limite_entrega = DateUtils.toDateWithHour(date.soon().toISOString());
const data_limite_recuperacao = DateUtils.toDateWithHour(date.future().toISOString());

export const EntregaMock: Entrega = {
  id: undefined,
  trabalhoId: undefined,
  trabalho_id: undefined,
  capa: '',
  titulo: '',
  respostas: [new QuestaoResposta()],
  palavras_chaves: [],
  materiais: undefined,
  publicar: false,
  created_at: undefined,
  status: undefined,
  status_anterior: undefined,
  perfil: {
    id: 1,
    usuario_id: 0,
    apelido: 'Duda De Latorre',
    empresa: 'Empresa teste',
    linkedin: 'https://www.linkedin.com/',
    biografia: '',
    foto_perfil: '',
  },
  usuario: undefined,
  video: '',
  nota: undefined,
  nota_adicional: undefined,
  nota_entrega: undefined,
  nota_minima: undefined,
  nota_recuperacao: undefined,
  data_entrega: undefined,
  data_entrega_adicional: undefined,
  data_entrega_recuperacao: undefined,
  data_adicional: undefined,
  data_limite_entrega,
  data_limite_recuperacao,
  comentarios: [],
  observacao: '',
  data_publicacao: undefined,
  comentario: '',
  devolutivas: undefined,
  usuario_id: undefined,
};
