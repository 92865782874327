import { SaveGradeConfirmData, DeliveryStatus } from '@/models';

const { AWAITING_EVALUATION, PENDING_STUDENT_CORRECTION } = DeliveryStatus;

export const SaveConfirmMock: SaveGradeConfirmData = {
  id: 1,
  from: AWAITING_EVALUATION,
  to: PENDING_STUDENT_CORRECTION,
  phrase: 'test',
};
