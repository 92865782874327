import { StatementData, QuestionModel, FileUpload } from '@/models';

export const enunciadoMock: StatementData = {
  discipline: 'O Cliente Digital e Estratégia de Marketing Digital',
  // eslint-disable-next-line max-len
  title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo dictumst adipiscing quis lorem in gravida ut nisl vulputate.',
  files: [new FileUpload(new File([], 'elementum-gravida.png'))],
  videoUrl: 'https://vimeo.com/510814675',
};

export const questionsMock: QuestionModel[] = [
  {
    id: 1,
    required: true,
    // eslint-disable-next-line max-len
    question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo dictumst adipiscing quis lorem in gravida ut nisl vulputate.',
  },
  {
    id: 2,
    required: false,
    // eslint-disable-next-line max-len
    question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo dictumst adipiscing quis lorem in gravida ut nisl vulputate.',
  },
];
