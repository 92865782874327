
import { Component, Prop, Vue } from 'vue-property-decorator';

import BackArrow from '@/assets/icons/BackArrow.svg';

import Cover from '@/components/shared/Cover.vue';

import {
  Breakpoint,
  CoverData,
  CssVariables,
  ScrollDirection,
  RouteName,
} from '@/models';

const { PUBLISHED_ASSIGNMENT } = RouteName;

@Component({
  name: 'CoverCarousel',
  components: {
    Cover,
    BackArrow,
  },
})
export default class CoverCarousel extends Vue {
  @Prop() public data: CoverData[];

  $refs: {
    carouselCover: HTMLDivElement;
    carousel: HTMLDivElement;
  };

  public x = 0;
  public showNavigation = false;
  public showRightArrow = true;
  public showLeftArrow = false;
  public scrollDirection = ScrollDirection;
  public PUBLISHED_ASSIGNMENT = PUBLISHED_ASSIGNMENT;

  private blocks = 0;
  private currentPage = 1;
  private pages = 0;
  private remaining = false;

  private debounce: number;

  private coverWidth = 0;

  onScroll({ deltaX }: WheelEvent) {
    const direction = deltaX > 0 ? ScrollDirection.RIGHT : ScrollDirection.LEFT;

    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this.scroll(direction);
    }, 40);
  }

  mounted() {
    window.addEventListener('resize', this.listeners);
    this.listeners();
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.listeners);
  }

  private listeners() {
    this.canShowArrowsNavigation();
    this.handleCoverSizes();
  }

  private handleCoverSizes() {
    const blocksByWidth = (windowWidth: number) => {
      if (windowWidth <= Breakpoint.XS) return 2;
      if (windowWidth <= Breakpoint.SM) return 2;
      if (windowWidth <= Breakpoint.MD) return 3;
      if (windowWidth <= Breakpoint.LG) return 4;
      return 6;
    };

    const coverHeightProportion = 1.5;
    this.blocks = blocksByWidth(window.innerWidth);
    this.pages = Math.ceil(this.data.length / (this.blocks - 1));
    this.coverWidth = Math.ceil(window.innerWidth / this.blocks - 20);
    const newCoverHeight = Math.floor(this.coverWidth * coverHeightProportion);
    document.documentElement.style.setProperty(
      CssVariables.COVER_HEIGHT,
      `${newCoverHeight}px`,
    );
  }

  private canShowArrowsNavigation() {
    const scroller = this.$refs.carouselCover;
    this.showNavigation = scroller.offsetWidth !== scroller.scrollWidth;
  }

  private allowedRange(maxWidth: number, toSum: number): number {
    this.x += toSum;

    if (this.x < 0) this.x = 0;
    if (this.x >= maxWidth) this.x = maxWidth;

    return this.x;
  }

  public scroll(direction: ScrollDirection): void {
    if (this.currentPage > 0 || this.currentPage < this.pages) {
      this.currentPage += direction === ScrollDirection.LEFT ? -1 : 1;
    }

    const scroller = this.$refs.carouselCover;
    const width = scroller.offsetWidth;
    const maxWidth = scroller.scrollWidth;

    const coverPadding = this.coverWidth / 4;
    const scrollAdd = width - ((this.coverWidth + 20));

    const sum = direction === ScrollDirection.LEFT ? -scrollAdd + -10 : scrollAdd + 10;
    const x = this.allowedRange(maxWidth, sum);

    const proportion = this.blocks > 3 ? (5 / 3) : (10 / 3);

    const discount = coverPadding - (proportion * this.currentPage);

    if (
      this.currentPage < this.pages
      && this.showRightArrow
      && !this.remaining
    ) {
      this.showLeftArrow = true;
    }

    if (!this.showRightArrow || this.remaining) {
      this.showRightArrow = true;
    }

    if (this.currentPage === 1) {
      this.showLeftArrow = false;
      this.remaining = false;
    }

    if (this.currentPage === this.pages) {
      this.showRightArrow = false;
      this.remaining = true;
    }

    scroller.scrollTo(x - discount, 0);
  }
}
