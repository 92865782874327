import { Class } from '@/models/class';

export const ClassMock = {
  classWithDeliveryAndRecoveryDate: new Class({
    id: 1,
    nome: 'Teste Com Data de Entrega',
    data_publicacao: '2222-12-21 00:00:00',
    data_limite_entrega: '3333-12-22 00:00:00',
    data_limite_recuperacao: '3000-12-22 00:00:00',
    composicao_nota: false,
  }, 1),
  classWithoutDeliveryAndRecoveryDate: new Class({
    id: 2,
    nome: 'Teste Sem Data de Entrega',
    data_publicacao: '2222-12-21 00:00:00',
    composicao_nota: false,
  }, 2),
  classChecked: new Class({
    id: 3,
    nome: 'Teste Checked',
    data_publicacao: '2222-12-21 00:00:00',
    data_limite_entrega: '3333-12-22 00:00:00',
    data_limite_recuperacao: '3000-12-22 00:00:00',
    composicao_nota: false,
  }, 0, true),
};
