import { AssignmentObjectModel, AssignmentObjectStatus } from '@/models';

const { PUBLISHED, DRAFT } = AssignmentObjectStatus;

export const objectList: AssignmentObjectModel[] = [{
  id: 32,
  course: {
    id: 1,
    name: 'Curso 1',
    assignments: [],
    slug: '33',
    type: '',
  },
  discipline: {
    id: 1,
    name: 'Disciplina 1',
  },
  status: PUBLISHED,
  class: {
    id: 1,
    name: 'Março 2021',
    deliveryDate: new Date('12/31/2021 00:00:00'),
    publicationDate: new Date('03/11/2021 00:00:00'),
    recoveryDeliveryDate: new Date('07/01/2022 00:00:00'),
    checked: true,
    assignmentObjectId: 32,
    composingGrade: true,
  },
  classes: [{
    id: 1,
    name: 'Março 2021',
    deliveryDate: new Date('12/31/2021 00:00:00'),
    publicationDate: new Date('03/11/2021 00:00:00'),
    recoveryDeliveryDate: new Date('07/01/2022 00:00:00'),
    checked: true,
    assignmentObjectId: 32,
    composingGrade: true,
  }],
  deletable: false,
  createdAt: new Date('2021-05-15 00:00:00'),
},
{
  id: 45,
  course: {
    id: 2,
    name: 'Curso 2',
    assignments: [],
    slug: '33',
    type: '',
  },
  discipline: {
    id: 2,
    name: 'Disciplina 2',
  },
  status: DRAFT,
  classes: [{
    id: 2,
    name: 'Abril 2021',
    deliveryDate: new Date('12/31/2021 00:00:00'),
    publicationDate: new Date('03/11/2021 00:00:00'),
    checked: true,
    assignmentObjectId: 45,
    composingGrade: true,
  },
  {
    id: 3,
    name: 'Abril 2021',
    deliveryDate: new Date('12/31/2021 00:00:00'),
    publicationDate: new Date('03/11/2021 00:00:00'),
    checked: true,
    assignmentObjectId: 45,
    composingGrade: false,
  }],
  deletable: true,
  createdAt: new Date('2021-05-16 00:00:00'),
}, {
  id: 32,
  course: {
    id: 1,
    name: 'Curso 1',
    assignments: [],
    slug: '33',
    type: '',
  },
  discipline: {
    id: 1,
    name: 'Disciplina 1',
  },
  status: PUBLISHED,
  class: {
    id: 1,
    name: 'Março 2021',
    deliveryDate: new Date('12/31/2021 00:00:00'),
    publicationDate: new Date('03/11/2021 00:00:00'),
    checked: true,
    assignmentObjectId: 32,
    composingGrade: false,
  },
  classes: [{
    id: 1,
    name: 'Março 2021',
    deliveryDate: new Date('12/31/2021 00:00:00'),
    publicationDate: new Date('03/11/2021 00:00:00'),
    checked: true,
    assignmentObjectId: 32,
    composingGrade: false,
  }],
  deletable: false,
  createdAt: new Date('2020-05-15 00:00:00'),
}];
