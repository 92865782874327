import {
  FloatingMenuContent,
  FloatingMenuEvents,
  FloatingMenuItem,
  RouteName,
} from '@/models';

const { ASSIGNMENT_OBJECT_EDIT, PROFILE } = RouteName;

const {
  FLOATING_MENU_LOGOUT,
  DELETE,
} = FloatingMenuEvents;

const { LINK_TYPE, SIMPLE_TYPE } = FloatingMenuContent;

export const FloatingMenuItemsMock: FloatingMenuItem[] = [{
  type: LINK_TYPE,
  id: 1,
  text: 'Editar',
  name: ASSIGNMENT_OBJECT_EDIT,
}, {
  type: LINK_TYPE,
  id: 2,
  text: 'Editar Perfil',
  name: PROFILE,
}, {
  type: SIMPLE_TYPE,
  id: 3,
  text: 'Logout',
  action: FLOATING_MENU_LOGOUT,
}, {
  type: SIMPLE_TYPE,
  id: 4,
  text: 'Excluir',
  action: DELETE,
}];

export const FloatingMenuMock = {
  data: FloatingMenuItemsMock,
  tip: false,
  hover: false,
  showMenu: false,
  bordered: false,
};
