import fakeTimers from '@sinonjs/fake-timers';

import { Delivery, DeliveryGradesFormHeader } from '@/models';
import { GradeFormModel } from '@/models/grade-form';

import { TimestampNowMock } from './date';
import { EntregaMock } from './entrega';

const deliveryMock = new Delivery(EntregaMock);
const mockDate = fakeTimers.install({
  now: TimestampNowMock,
});

const { RECOVERY, REGULAR, ADITIONAL } = DeliveryGradesFormHeader;

export const GradesFormMock: GradeFormModel[] = [
  new GradeFormModel(
    REGULAR,
    {
      ...deliveryMock,
      grade: 10,
      conceptGrade: 'A',
      deliveryDate: new Date(),
    },
    10,
  ),
  new GradeFormModel(RECOVERY, deliveryMock),
  new GradeFormModel(ADITIONAL, deliveryMock, deliveryMock.grade, deliveryMock.aditionalDueDate),
];

mockDate.uninstall();
