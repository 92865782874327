import { CoverData, NoteBoxData, NoteBoxSource } from '@/models';

export const coverMock: CoverData = {
  id: 1,
  authorName: 'Samantha Johns',
  // eslint-disable-next-line max-len
  coverImage: 'https://images.unsplash.com/photo-1615048735738-fc51e7422ef5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1846&q=80',
  authorImage: '@/assets/img/mock/samantha.png',
  title: 'Aperiam non eos doloribus est voluptas.',
};

export const noteBoxComentario: NoteBoxData = {
  source: NoteBoxSource.COMENTARIO,
  // eslint-disable-next-line max-len
  content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec orci adipiscing massa vivamus. Rutrum egestas arcu, id elementum metus. Velit, tristique sit a sit posuere fusce sed in. Id nunc hendrerit vulputate a condimentum est eget ipsum.',
};

export const noteBoxNota: NoteBoxData = {
  source: NoteBoxSource.NOTA,
  content: '10,00',
};
